/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import React, { PropsWithChildren } from "react"
import { Heading, suomifiDesignTokens as tokens } from "suomifi-ui-components"
import { Link } from 'react-router-dom'
import { FlexColTight, PageHeader, PageHeadingWrapper, panelWithShadowCss } from "./CommonStyles"

type Props = {
  title?: string,
  titleAction?: React.ReactElement,
  additionalInfo?: {
    text: string,
    path: string
  },
  styles?: SerializedStyles
}

const Card: React.FC<PropsWithChildren<Props>> = props => {

  const styles = css({});

  return (
    <div css={[styles, props.styles]}>
      <FlexColTight css={panelWithShadowCss}>
        {props.title && 
          <PageHeader>
            <PageHeadingWrapper>
              <Heading variant="h2" smallScreen>
                {props.title}
              </Heading>
            </PageHeadingWrapper>
            {props.titleAction}
          </PageHeader>
        }

        {props.children}

        { 
          !props.additionalInfo 
            ? null
            : (<div style={{ marginTop: "auto" }}>
                <Link to={props.additionalInfo.path}>
                  <span style={{ color: tokens.colors.accentBase }}>&rsaquo; </span>
                    {props.additionalInfo.text}
                  </Link>
              </div>)
        }
      </FlexColTight>
    </div>
  );
};

export default Card;
