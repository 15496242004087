/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import { Breadcrumbs as MuiBreadcrumbs, Link, Stack, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export interface BreadcrumbItem {
  href: string,
  label: string,
  current: boolean,
  element?: React.ReactElement // To use custom componen instead of label translation
}

const Breadcrumbs: React.FC<{items: BreadcrumbItem[]}> = ({items}) => {
  const { t } = useTranslation(["translation"]);

  const styles = css({
    marginBottom: tokens.spacing.xxs,
    '@media (min-width: 992px)': {
      marginBottom: tokens.spacing.xs,
    }
  });

  return (
    <div css={styles}>
      <Stack spacing={2}>
        <MuiBreadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          { items.map((item, index) => (
            item.element ? 
              item.element : 
              item.current ? 
                <Typography key={`breadcrumb-${index}`}>
                  {t(`app.navigation.${item.label}`)}
                </Typography> :
                <Link component={RouterLink} to={item.href} underline="hover" key={`breadcrumb-${index}`} color="inherit">
                  {t(`app.navigation.${item.label}`)}
                </Link>
          ))}
        </MuiBreadcrumbs>
      </Stack>
    </div>
  );
}

export default Breadcrumbs;