import styled from '@emotion/styled'
import {css} from "@emotion/react";
import {Heading, Notification, Paragraph, suomifiDesignTokens as tokens} from "suomifi-ui-components";

export const panelWithShadowCss = css`
  background-color: ${tokens.colors.whiteBase};
  box-shadow: #29292924 0px 1px 2px 0px, #2929291f 0px 1px 5px 0px;
  border-radius: 2px;
  padding: ${tokens.spacing.m};
  @media (max-width: 600px) {
    padding: ${tokens.spacing.xxs};
  }
`;

export const panelWithBorderCss = css`
  background-color: ${tokens.colors.whiteBase};
  border: 1px solid ${tokens.colors.depthLight3};
  padding: ${tokens.spacing.l};
`;

export const MainWithBorder = styled.main`
  ${panelWithBorderCss};
  min-height: 400px;
`;

export const PanelWithBorder = styled.div`
  ${panelWithBorderCss}
`;

export const PanelWithShadow = styled.div`
  ${panelWithShadowCss}
`;

export const HeaderBackground = styled.div`
  background: ${tokens.colors.whiteBase};
  border-top: 4px solid ${tokens.colors.brandBase};
  border-bottom: 1px solid ${tokens.colors.depthLight3};
  padding: ${tokens.spacing.xl} 0;
`;

// export const HeaderBackground = styled.div`
//   background-image: linear-gradient(90deg,#18073a,#164194);
// `;

// export const HeaderBackgroundImage = styled.div`
//   background-image: url("/resources/images/yd-header.png");
//   background-repeat: no-repeat;
//   background-position: 50%;
//   background-size: 100% auto;
//   border-top: 4px solid ${tokens.colors.brandBase};
//   border-bottom: 1px solid ${tokens.colors.depthLight3};
//   padding: ${tokens.spacing.xxl} 0;
// `;

export const TopNavigationBackground = styled.div`
  background: ${tokens.colors.whiteBase};
  border-bottom: 1px solid ${tokens.colors.depthLight3};
`;

export const TopWalletBackground = styled.div`
  background: ${tokens.colors.brandBase};
  border-bottom: 1px solid ${tokens.colors.depthLight3};
`;

export const ContentContainer = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 ${tokens.spacing.m};
  width: 100%;
  max-width: 1600px;
`;

export const Heading1WithBottomBorder = styled(Heading)`
  border-bottom: 1px solid ${tokens.colors.depthLight3};
  padding-bottom: ${tokens.spacing.s};
`;

export const Heading2WithBottomBorder = styled(Heading)`
  border-bottom: 1px solid ${tokens.colors.depthLight3};
  padding-bottom: ${tokens.spacing.s};
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > * {
    flex: 1;
  }
  & > * + * {
    margin-left: ${tokens.spacing.l};  
  };
  @media (max-width: 800px) {
    display: block;
    & > * + * {
      margin-left: 0;
      margin-top: ${tokens.spacing.l};
    }
  }
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: ${tokens.spacing.l};  
  };
`;

export const FlexColTight = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: ${tokens.spacing.m};  
  };
`;

export const Table = styled.table`
  border-collapse: collapse;
  font-size: ${tokens.values.typography.bodyText.fontSize.value}${tokens.values.typography.bodyText.fontSize.unit};
  margin: ${tokens.spacing.m} 0;
  table-layout: fixed;
  width: 100%;
  & > thead > tr > th,
  & > thead > tr > td,
  & > tbody > tr > th,
  & > tbody > tr > td {
    border-bottom: 1px solid ${tokens.colors.depthLight2};
    padding: ${tokens.spacing.s};
    text-align: left; 
    vertical-align: middle;
  };
  & > thead > tr > th.right,
  & > thead > tr > td.right,
  & > tbody > tr > th.right,
  & > tbody > tr > td.right {
    text-align: right; 
  };
  & > thead > tr > th.center,
  & > thead > tr > td.center,
  & > tbody > tr > th.center,
  & > tbody > tr > td.center {
    text-align: center; 
  };
  & > tbody > tr.nested-row > th {
    padding-left: ${tokens.spacing.l};
  };
`;

export const TableSmall = styled.table`
  border-collapse: collapse;
  font-size: ${tokens.values.typography.bodyTextSmall.fontSize.value}${tokens.values.typography.bodyTextSmall.fontSize.unit};
  margin: ${tokens.spacing.m} 0;
  table-layout: fixed;
  width: 100%;
  & > thead > tr > th,
  & > thead > tr > td,
  & > tbody > tr > th,
  & > tbody > tr > td {
    border-bottom: 1px solid ${tokens.colors.depthLight2};
    padding: ${tokens.spacing.xs};
    text-align: left; 
    vertical-align: middle;
  };
  & > thead > tr > th.right,
  & > thead > tr > td.right,
  & > tbody > tr > th.right,
  & > tbody > tr > td.right {
    text-align: right; 
  };
  & > thead > tr > th.center,
  & > thead > tr > td.center,
  & > tbody > tr > th.center,
  & > tbody > tr > td.center {
    text-align: center; 
  };
  & > tbody > tr.nested-row > th {
    padding-left: ${tokens.spacing.l};
  };
`;

export const TableStyleRow = styled.div`
  border-bottom: 1px solid ${tokens.colors.depthLight2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  & > * {
    flex: 1;
    padding: ${tokens.spacing.s};
  } 
`;

export const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${tokens.colors.depthBase};
  height: 100%;
`;

export const HideOnSmallDisplay = styled.span`
  @media (max-width: 800px) {
    display: none;
  }
`;

export const MessagesDiv = styled.span`
  & > span.messages {
    margin-left: ${tokens.spacing.xs};
    vertical-align:
  };
`;

export const InfoNotification = styled(Notification)`
  & > section {
    border-top: 4px solid ${tokens.colors.brandBase};
  }
`;

export const PageHeader = styled.div`
  display: flex;
`

export const PageHeadingWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`

export const Line = styled(Paragraph)`
  text-align: center;
  border-bottom: 1px solid ${tokens.colors.depthBase};
  line-height: 0.1em;
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`

export const Span = styled.span`
  background: ${tokens.colors.whiteBase};
  padding: 0 10px;
  color: ${tokens.colors.depthDark1};
  font-size: 0.9rem;
  margin-right: 0%;
  font-weight: 500;
`
