import React, { useEffect, useState, PropsWithChildren, useContext } from "react";
import log from "loglevel";
import _ from "lodash";
import * as YkpApi from "../api/YkpApi";
import { UserStory } from "../models/YkpModels";

export enum AppStateProperty {
  IMPL_USER_STORIES = "implUserStories"
}

export enum AppStatePropertyState {
  UNINITIALIZED = "uninitialized",
  INITIALIZED = "initialized",
  ERROR = "error"
}

const setDefaultPropStates = () => {
  const states: {[id: string]: AppStatePropertyState} = {};
  Object.entries(AppStateProperty).forEach(entry => {
    states[`${entry[1]}`] = AppStatePropertyState.UNINITIALIZED;
  });
  return states;
}

interface AppState {
  isLoadingApp: boolean,
  implUserStories: UserStory[],
  propertyStates: {[id: string]: AppStatePropertyState}
}

export type AppStateType = AppState & {
  // Functions
  refreshAppState: () => Promise<void>,
  resetAppState: () => Promise<void>,
  getImplUserStoriesAsync: () => Promise<UserStory[]>
}

export const DefaultAppState: AppState = {
  isLoadingApp: true,
  implUserStories: [],
  propertyStates: setDefaultPropStates()
}

export const DefaultAppStateContext: AppStateType = {
  ...DefaultAppState,
  refreshAppState: () => new Promise<void>(resolve => resolve()),
  resetAppState: () => new Promise<void>(resolve => resolve()),
  getImplUserStoriesAsync: () => new Promise<UserStory[]>(() => [])
}

// AppContext with default values. AppContextProvider replaces defaults with the real values.
export const AppStateContext = React.createContext<AppStateType>(DefaultAppStateContext);

const AppContextProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [appState, setAppState] = useState<AppState>(DefaultAppState);
  // Contains ongoing request promises. To update state use methods addPromise and removePromise
  const [promises, setPromises] = useState<{[type: string]: Promise<any>}>({});
  const logger = log.getLogger(AppContextProvider.name);

  // private
  const addPromise = (type: string, promise: Promise<any>) => {
    setPromises(oldState => ({...oldState, [type]: promise}));
  }

  // private
  const removePromise = (type: string) => {
    setPromises(oldState => _.omit(oldState, type));
  }

  // Run this useEffect only once when app loads
  useEffect(() => {
    loadAppStateAsync();
  }, []);
  
  const loadAppStateAsync = async (): Promise<void> => {
    logger.debug("ACP loadAppStateAsync");
    setAppState(appState => ({...appState, isLoadingApp: true}));
    getImplUserStoriesAsync()
    .finally(() => setAppState(appState => ({...appState, isLoadingApp: false})));
  }

  const refreshAppState = async (): Promise<void> => {
    await loadAppStateAsync();
  }

  const resetAppState = async (): Promise<void> => {
    setAppState(() => DefaultAppState);
  }

  const getImplUserStoriesAsync = async (): Promise<UserStory[]> => {  
    const AppStateProp = AppStateProperty.IMPL_USER_STORIES;
    return promises[AppStateProp] ?? (
      async (): Promise<UserStory[]> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = YkpApi.GetContent("ImplementedUserStories")
        .then(res => {
          if (res.data) {
            setAppState(oldState => ({...oldState, implUserStories: res.data}));
            return res.data;
          }
          logger.debug("ACP getImplUserStoriesAsync response without response data", res);
          propState = AppStatePropertyState.ERROR;
          return [];
        })
        .catch(err => {
          logger.debug("ACP getImplUserStoriesAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return [];
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )();
  }

  return (
    <AppStateContext.Provider value={{
      ...appState,
      refreshAppState,
      resetAppState,
      getImplUserStoriesAsync
    }}>
      {children}
    </AppStateContext.Provider>
  );
}

export const useAppStateContext = () => {
  const appContext = useContext(AppStateContext);
  if (appContext === undefined) {
    throw new Error("useAppStateContext must be used within a AppContextProvider");
  }
  return appContext;
}

export default AppContextProvider;