/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import Header from './components/common/Header';
import GlobalStyles from './components/common/GlobalStyles';
import FrontPage from './components/pages/frontpage/FrontPage';
import { ContentContainer, HeaderBackground, TopNavigationBackground } from './components/common/CommonStyles';
import AppContextProvider, { AppStateContext } from './state/AppStateContext';
import NavBar from './components/common/NavBar';
import PageNotFoundView from './components/pages/PageNotFoundView';
import OpenApiView from './components/pages/openapi/OpenApiView';
import CollaborationView from './components/pages/collaboration/CollaborationView';
import ScrollToAnchor from './components/ScrollToAnchor';

const styles = {
  contentContainer: css({
    padding: tokens.spacing.m,
    '@media (min-width: 992px)': {
      padding: tokens.spacing.xxl
    }
  })
}

const App: React.FC = () => {
  return (
    <Suspense fallback="loading">
      <AppContextProvider>
        <GlobalStyles />
        <Router>
          <ScrollToAnchor/>
          <HeaderBackground>
            <ContentContainer>
              <Header/>
            </ContentContainer>
          </HeaderBackground>

          <TopNavigationBackground>
            <ContentContainer>
              <NavBar/>
            </ContentContainer>
          </TopNavigationBackground>

          <AppStateContext.Consumer>
            {state => 
              <Routes>
                { RoutePaths.map((it, i) => 
                  <Route key={`route-${i}`} path={it.to} element={it.element}/>
                )}
                <Route path="/login" element={<Navigate to={"/"}/>}/>
                <Route path="*" element={<PageNotFoundView/>}/>
              </Routes>
            }
          </AppStateContext.Consumer>
        </Router>
      </AppContextProvider>
    </Suspense>
  )
}

export interface RoutePathProps {
  to: string;
  label: string;
  element: React.ReactNode | null;
  visibleInNav: boolean;
}

export const RoutePaths: RoutePathProps[] = [
  // Visible in navigation bar
  { to: "/", label: "frontpage", element: <FrontPage/>, visibleInNav: true},
  { to: "/openapi", label: "openapi", element: <OpenApiView/>, visibleInNav: true}
]

export default App
