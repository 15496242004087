import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import log from "loglevel";
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { AppStateProperty, AppStatePropertyState, useAppStateContext } from "../state/AppStateContext";

const UserStoriesTable: React.FC = () => {
  const appContext = useAppStateContext();
  const logger = log.getLogger(UserStoriesTable.name);
  const { t } = useTranslation(["translation"]);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (appContext.propertyStates[AppStateProperty.IMPL_USER_STORIES] !== AppStatePropertyState.UNINITIALIZED) {
      setIsInitialized(() => true);
    }
  }, [appContext.propertyStates[AppStateProperty.IMPL_USER_STORIES]]);

  return (
    <TableContainer component={Paper} sx={{width: "100%"}}>
      <Table aria-label={t("app.components.UserStoriesTable.ariaLabel")}>
        <colgroup>
          <col width="10%" />
          <col width="20%" />
          <col width="35%" />
          <col width="35%" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>{t("app.components.UserStoriesTable.UserStoryID")}</TableCell>
            <TableCell>{t("app.components.UserStoriesTable.UserStoryRole")}</TableCell>
            <TableCell>{t("app.components.UserStoriesTable.UserStoryTask")}</TableCell>
            <TableCell>{t("app.components.UserStoriesTable.UserStoryMotive")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { !isInitialized && 
            <TableRow>
              <TableCell colSpan={4} align="center">
                <CircularProgress sx={{margin: tokens.spacing.xxs}}/>
              </TableCell>
            </TableRow>
          }
          { appContext.implUserStories.map((it, index) => (
            <TableRow
              key={index}
            >
              <TableCell component="th" scope="row">{it.id}</TableCell>
              <TableCell>{it.asA}</TableCell>
              <TableCell>{it.iNeedTo}</TableCell>
              <TableCell>{it.soThatICan}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UserStoriesTable;