import React, { useEffect, useState } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css"
import { FormControl, InputLabel, ListItemText, MenuItem, Select, Tooltip } from "@mui/material";
import Page from "../../common/Page";


const openApiUrl = process.env.REACT_APP_OPENAPI_URL || "/openapi.yml";

interface OpenApiOption {
  title: string,
  const: string,
  description: string
}

const CustomSelect: React.FC<any> = (props) => {
  const [options, setOptions] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const { schema } = props;
    const name = props.description;
    const oneOfValues = schema && schema.get ? schema.get("oneOf") : null;
    if (oneOfValues) {
      const options: OpenApiOption[] = oneOfValues.map((it:any) => {
        return {
          title: it.get("title"),
          const: it.get("const"),
          description: it.get("description")
        }
      });
      const empty = [<MenuItem key={"item-empty"} value={""}>
        {"--"}
      </MenuItem>];
      const menuItems = empty.concat(options
      .map(it => (
        <MenuItem key={`${name}-${it.const}`} value={it.const}>
          <Tooltip title={<ListItemText primary={it.description?.length > 0 ? it.description : it.title} secondary={it.const} secondaryTypographyProps={{color: "white", variant: "caption"}}/>} placement="right-end">
            <ListItemText primary={`${it.title} (${it.const})`}/>
          </Tooltip>
        </MenuItem>
        )
      ));
      setOptions(() => menuItems);
    }
  }, []);

  return (
      <FormControl sx={{backgroundColor: "#fafafa", width: "100%", maxWidth: "340px", margin: "5px 0", "& .Mui-disabled": {cursor: (props.disabled ? "not-allowed !important" : "")}}} disabled={props.disabled}>
        <InputLabel style={{fontSize: "100%", color: "#888", fontWeight: "normal", margin: "0"}}>{props.description}</InputLabel>
        <Select
          name={props.description}
          labelId={props.description}
          value={props.value ?? ""}
          onChange={e => props.onChange(e.target.value)}
          variant="outlined"
          required
        >
          { options }
        </Select>
      </FormControl>
  );
}

const OpenApiView: React.FC = () => {
  return (
    <Page>
      <SwaggerUI url={openApiUrl} plugins={[{components: { JsonSchema_string_enum: CustomSelect}}]}/>
    </Page>
  );
}

export default OpenApiView;