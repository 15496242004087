import { Global, css } from '@emotion/react';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';

const styles = css({
  body: {
    backgroundColor: tokens.colors.depthLight3,
    fontFamily: tokens.values.typography.bodyText.fontFamily,
    fontSize: `${tokens.values.typography.bodyText.fontSize.value}${tokens.values.typography.bodyText.fontSize.unit}`,
    lineHeight: `${tokens.values.typography.bodyText.lineHeight.value}${tokens.values.typography.bodyText.lineHeight.unit}`,
    margin: 0
  },
  a: {
    color: tokens.colors.highlightBase,
    textDecoration: 'none',

    '&:visited': {
      color: tokens.colors.accentTertiaryDark1
    }
  },
  label: {
    fontWeight: tokens.values.typography.bodySemiBold.fontWeight
  },
  hr: {
    border: 0,
    borderBottom: `1px solid ${tokens.colors.depthLight3}`,
    margin: `${tokens.spacing.m} 0`
  },
  'ul, ol': {
    margin: 0,
    padding: 0,
    '@media (max-width: 1200px)': {
      listStylePosition: 'inside'
    }
  },
  li: {
    margin: 0,
    padding: 0
  },
  p: {
    margin: `${tokens.spacing.m} 0`
  }
});

const GlobalStyles = () => (
  <Global styles={styles} />
);

export default GlobalStyles;