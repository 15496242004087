/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import Page from '../../common/Page';
import Card from '../../common/Card';
import UserStoriesTable from '../../UserStoriesTable';
import { Typography } from '@mui/material';

const FrontPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <Card 
        title={t("app.components.FrontPage.heading")}
        styles={css({
          paddingTop: tokens.spacing.xxs,
          paddingBottom: tokens.spacing.xxs,
          '@media (min-width: 992px)': {
            paddingTop: tokens.spacing.xs,
            paddingBottom: tokens.spacing.xs,
          }
        })}
      >
        <Typography sx={{marginTop: tokens.spacing.m}}>
          <Trans
            i18nKey="app.components.FrontPage.infotext1"
            components={{
              link1: <a href="https://www.yrityksendigitalous.fi" target="_blank" rel="noreferrer"></a>,
            }}
          />
        </Typography>
        <Typography sx={{marginTop: tokens.spacing.m}}>
          <Trans
            i18nKey="app.components.FrontPage.infotext2"
            components={{
              link1: <a href="https://www.yrityksendigitalous.fi/kokeilut-ja-yhteiskehittaminen/meneillaan-olevat-kokeilut/#tiedonsiirron-yhtenaisilla-rajapinnoilla-kohti-yhden-ilmoituskerran-periaatetta" target="_blank" rel="noreferrer"></a>,
              link2: <a href="https://minisuomi.fi" target="_blank" rel="noreferrer"></a>
            }}
          />
        </Typography>
      </Card>
      <Card 
        title={t("app.components.FrontPage.userStoriesTableHeading")}
        styles={css({
          paddingTop: tokens.spacing.xxs,
          paddingBottom: tokens.spacing.m,
          '@media (min-width: 992px)': {
            paddingTop: tokens.spacing.xs,
            paddingBottom: tokens.spacing.xxl,
          }
        })}
      >
        <UserStoriesTable/>
      </Card>
    </Page>
  );
}

export default FrontPage;