import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Page from '../common/Page';
import { BreadcrumbItem } from '../common/Breadcrumbs';
import Card from '../common/Card';

interface Props {
  title?: string,
  breadCrumbs?: BreadcrumbItem[]
}

const PageNotFoundView: React.FC<PropsWithChildren<Props>> = ({title, breadCrumbs, children}) => {
  const { t } = useTranslation();

  return (
    <Page
      breadcrumbs={breadCrumbs}
    >
      <Card title={ title ?? t("app.components.PageNotFoundView.title")}>
        { children ?? <Link to={"/"}>{t("app.components.PageNotFoundView.description")}</Link>}
      </Card>
    </Page>
  );
}

export default PageNotFoundView;