import axios, { AxiosResponse } from 'axios'
import { UserStory } from '../models/YkpModels';

const apiRootUrl = process.env.REACT_APP_YKP_API_URL || 'http://localhost:7071/api'
const apiKey = process.env.REACT_APP_YKP_API_KEY || "";

const api = axios.create({
  baseURL: apiRootUrl,
  headers: {
    "x-functions-key": apiKey
  },
})

export async function GetContent(label: string): Promise<AxiosResponse<UserStory[]>> {
  return api.get("/cms/content/get", {params: {label}});
}
